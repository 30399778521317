import { Injectable, inject } from '@angular/core'

import {Annotations, YAxisName} from "plotly.js"
import {BehaviorSubject, firstValueFrom} from "rxjs"

import {DiverInstance, Measurement, MeasureType, VideObject} from "../../api/api-types"
import {LogContainer} from "../../log-list/log-container"
import {SlimObject} from "./diver.component"
import {VideDataService} from "../../api/vide-data.service"
import {idDefined, isDefined} from "../../shared/vide-helper"
import {pick, PlotlyLayout, PlotlyMouseEvent} from "../../vide-types"

/** Measurements as found in DiverOffice files */
export interface DiverMeasurement {
    dateTime: string
    value: number
    temp: number
}

export const DIVER_DATA_TYPES = [
    'Uncompensated',
    'Unreferenced',
    'Referenced',
    'Air pressure (baro diver)'
] as const

export type DiverDataType = typeof DIVER_DATA_TYPES[number]

export interface DiverFileData {
    readonly measurements: readonly DiverMeasurement[],
    readonly serial: string,
    readonly type: DiverDataType,
    readonly head: readonly string[]
    readonly filename: string
}

export type DiverDatum = {
    value: number,
    time_t: number,
    dateTime: string,
    id?: number,
}

@Injectable({
    providedIn: 'root'
})
export class DiverService {
    private readonly dataService = inject(VideDataService);

    static readonly plotColors = {
        // temperature: "#000", // too black
        temperature: "#555", // ljussvart

        // D3 colors
        pressure: "#FF7F0E",
        result: "#2CA02C",
        uncompensated: "#D62728",
        unreferenced: "#1F77B4",
        references: "#9467BD",

// // The unused ones
//         a: "#8C564B",
//         a: "#E377C2",
//         a: "#7F7F7F",
//         a: "#BCBD22",
//         a: "#17BECF",
    } as const


    // Stuff for coordinating the components
    private readonly _selectedObject = new BehaviorSubject<SlimObject | null>(null)
    private readonly _fileData = new BehaviorSubject<DiverFileData | null>(null)
    private readonly _pressureData = new BehaviorSubject<null | readonly DiverDatum[]>(null)
    private readonly _localCompensatedData = new BehaviorSubject<null | {
        pressure: readonly DiverDatum[],
        unreferenced: readonly DiverDatum[],
        uncompensated: readonly DiverDatum[],
    }>(null)
    // corresponding observables
    readonly selectedObject$ = this._selectedObject.asObservable()
    readonly fileData$ = this._fileData.asObservable()
    readonly pressureData$ = this._pressureData.asObservable()
    readonly localCompensatedData$ = this._localCompensatedData.asObservable()

    readonly logs = new LogContainer()
    nrUncompensated: undefined | { database: number | undefined; file: number | undefined }
    nrUnreferenced: undefined | { database: number | undefined; file: number | undefined; local: number | undefined }

    async createNewObject(
        name: string,
    ) {
        const p = await firstValueFrom(this.dataService.projectNotNull$)
        const objectType = await firstValueFrom(this.dataService.objectTypeWell$)
        const objectStatus = await firstValueFrom(this.dataService.objectStatusActive$)
        const request = this.dataService.createObject(p, {
            name,
            object_status: objectStatus,
            object_type: objectType,
        })
        const result = await firstValueFrom(request)
        this.logs.add(result, `Create object ${name}`)
        if (result.success) {
            this.dataService.reloadProjectData()
        }
        return result
    }

    /**
     * Save the data to the object, and on success, reload project data.
     * Row with id are updates, rows without are created, so this may produce two http requests.
     *
     * @param data The clipped data
     * @param options
     */
    async saveToObject(
        data: readonly { dateTime: string, value: number, id?: number, }[],
        options: {
            object: SlimObject,
            measureType: MeasureType,
            update: boolean,
        },
    ) {
        const project = await firstValueFrom(this.dataService.projectNotNull$)
        const dataStatus = await firstValueFrom(this.dataService.dataStatusStandard$)
        const msg = `Save ${options.measureType.name} measurements to ${options.object.name} `

        let resUpdate
        let resCreate

        const updateRows = data.filter(idDefined)
        if (updateRows.length > 0) {
            resUpdate = await firstValueFrom(this.dataService.updateMeasurements(
                project,
                updateRows.map(x => ({
                    data_status: dataStatus,
                    error_code: null,
                    id: x.id,
                    measure_type: options.measureType,
                    measured_value: null,
                    object_id: options.object.id,
                    resulting_value: x.value,
                }))
            ))
            this.logs.add(resUpdate, msg)
            if (!resUpdate.success) {
                // Early exit on failure
                return resUpdate
            }
        }

        const createRows = data.filter(x => !idDefined(x))
        if (createRows.length > 0) {
            resCreate = await firstValueFrom(this.dataService.createMeasurements(
                project,
                {
                    dataStatus: dataStatus,
                    errorCode: null,
                    measureType: options.measureType,
                    object: options.object,
                },
                createRows.map(x => ({
                    measuretime: x.dateTime,
                    resultingValue: x.value,
                })),)
            )
            this.logs.add(resCreate, msg)

            if (!resCreate.success) {
                // Create failed, return the error.
                return resCreate
            }
        }
        const res = resUpdate || resCreate
        if (!res) {
            throw Error('Nothing to save')
        }
        // Something was done successfully
        this.dataService.reloadProjectData()
        return res

    }

    async createDiverAnnotation(object: Pick<VideObject, "id" | "name">, arg: {
        comment: string
        first_date: string
        serial_number?: string
        reference_measurement_id?: number
    }) {
        const p = await firstValueFrom(this.dataService.projectNotNull$)
        const res = await firstValueFrom(
            this.dataService.createDiverAnnotations(p, object, pick(arg, 'first_date', 'comment', 'serial_number', 'reference_measurement_id')))
        this.logs.add(res, 'Create diver annotation')
        if (res.success) {
            this.dataService.reloadProjectData()
        }
        return res
    }

    async deleteInstance(element: DiverInstance) {
        const p = await firstValueFrom(this.dataService.projectNotNull$)
        const res = await firstValueFrom(this.dataService.deleteDiverAnnotation(p, element))
        this.logs.add(res, 'Delete diver installation')
        if (res.success) {
            this.dataService.reloadProjectData()
        }
        return res
    }

    async deleteMeasurements(object: SlimObject, measurements: readonly Measurement[]) {
        const project = await firstValueFrom(this.dataService.projectNotNull$)
        const res = await firstValueFrom(
            this.dataService.deleteMeasurements(project, object, measurements.map(x => x.id)))
        this.logs.add(res, `Delete measurements`)
        if (res.success) {
            this.dataService.reloadProjectData()
        }
        return res
    }

    setObject(object: SlimObject | null) {
        this._selectedObject.next(object)
    }

    setFileData(data: DiverFileData | null) {
        this._fileData.next(data)
    }

    setPressureData(data: null | { data: readonly DiverDatum[]; unitName: string; object: VideObject }) {
        this._pressureData.next(data?.data ?? null)
    }

    setLocalCompensatedData(data: null | {
        pressure: readonly DiverDatum[];
        uncompensated: readonly DiverDatum[];
        unreferenced: readonly DiverDatum[];
        skipped: readonly DiverDatum[]
    }) {
        this._localCompensatedData.next(data)
    }
}

/**
 * Parses a file as from DiverOffice. Expects data to be in mH2O and degrees Celsius.
 *
 * @param filename
 * @param text
 */
export function parseDiverOfficeData(filename: string, text: string,): DiverFileData | null {
    const rows = text.split("\r\n")
    const length = rows.length
    let idx = 0
    while (idx < length && rows.at(idx) !== '[Logger settings]') {
        ++idx
    }
    let match
    while (idx < length && (match = rows.at(idx)?.match(/ {2}Serial number +=(.+)/)) === null) {
        ++idx
    }
    if (!match) {
        throw new Error('No serial number found')
    }
    const serial = match.at(1)!
    const headerUncompensatedM = 'Date/time;Pressure[mH2O];Temperature[°C]'
    const headerUncompensatedCm = 'Date/time;Pressure[cmH2O];Temperature[°C]'
    const headerUnrefCm = 'Date/time;Water head[cm];Temperature[°C]'
    const headerUnrefM = 'Date/time;Water head[m];Temperature[°C]'

    let current
    while (idx < length && (current = rows.at(idx)) !== undefined && !current.startsWith('Date/time;')) {
        ++idx
    }
    if (idx === length) {
        // Found no data header, maybe the format is different, and we should handle this too?
        throw new Error('No header row found, maybe the format is unknown')
    }
    let factor
    let type: DiverDataType
    switch (current) {
        case headerUnrefM:
            type = 'Unreferenced'
            factor = 1.0
            break
        case headerUncompensatedM:
            type = 'Uncompensated'
            factor = 1.0
            break
        case headerUnrefCm:
            type = 'Unreferenced'
            factor = 0.01
            break
        case headerUncompensatedCm:
            type = 'Uncompensated'
            factor = 0.01
            break
        default:
            throw new Error(`Unknown header row ${current}`)
    }
    const head = rows.splice(0, idx + 1)

    const data = []
    for (const row of rows) {
        if (row === "END OF DATA FILE OF DATALOGGER FOR WINDOWS") break
        const [dateTime, value, temp] = row.split(';')
        if (!dateTime || !value || !temp) throw new Error(`Cannot parse row ${row}`)
        const match = dateTime.match(/^(?<year>\d{4})\/(?<month>\d{2})\/(?<date>\d{2}) (?<time>\d{2}:\d{2}:\d{2})/)
        if (!match?.groups) throw new Error(`Cannot parse date-time ${dateTime}`)
        const year = match.groups['year']!
        const month = match.groups['month']!
        const date = match.groups['date']!
        const time = match.groups['time']!
        data.push({
            dateTime: `${year}-${month}-${date} ${time}`,
            value: factor * parseFloatComma(value),
            temp: parseFloatComma(temp),
        })
    }

    return {serial, measurements: data, head, type, filename: filename}
}

export function readAsText(file: File) {
    return new Promise<string>((resolve, reject) => {
        const reader = new FileReader()
        reader.onload = _ev => {
            const result = reader.result
            if (typeof result === 'string') {
                resolve(result)
            } else {
                reject('Type error')
            }
        }
        reader.readAsText(file, 'ISO-8859-1')
    })
}

export function annotationsToAnnotations(as: Array<Partial<Annotations>> & {}) {
    if (as.length === 0) {
        return []
    }
    const a = as.at(0)
    const b = as.at(1)
    const [begin, end] = (a && b && a.x && b.x && a.x > b.x) ? [b, a] : [a, b]
    if (begin) {
        begin.text = 'begin'
    }
    if (end) {
        end.text = 'end'
    }
    return [begin, end].filter(isDefined)
}

function parseFloatComma(str: string) {
    switch (countChar(',', str)) {
        case 1:
            return parseFloat(str.replace(',', '.'))
        case 0:
            return parseFloat(str)
        default:
            throw new Error('String has more than one comma')
    }
}

function countChar(char: string, string: string) {
    let finds = 0
    for (let i = 0; i < string.length; i++) {
        if (char === string.charAt(i)) finds++
    }
    return finds
}

export function getFilterFn<T extends Pick<DiverMeasurement, 'dateTime'>>(annotations: readonly Partial<Annotations>[]) {
    const begin = annotations?.at(0)?.x
    const end = annotations?.at(1)?.x
    const filters = [
        begin ? ((x: T) => begin <= x.dateTime) : undefined,
        end ? ((x: T) => x.dateTime <= end) : undefined,
    ].filter(isDefined)
    return (x: T) => filters.every(f => f(x))
}

export function figureClick(subject: BehaviorSubject<NonNullable<PlotlyLayout['annotations']>>, event: PlotlyMouseEvent) {
    const p = event.points.at(0)
    if (!p) return

    const x = p.x
    const y = p.y
    if (!x || !y) {
        return
    }
    if (x instanceof Date || y instanceof Date) {
        return
    }
    // TODO: make changes to DT package: add Date to x and y type
    const annotation: NonNullable<PlotlyLayout['annotations']>[number] = {
        x,
        y,
        // Types defined differently in different places...
        yref: p.data.yaxis as YAxisName,
    }
    const value = subject.value
    if (value.length > 1) {
        value.shift()
    }
    value.push(annotation)
    subject.next(value)
}

export function interpolate(a: { x: number, y: number }, b: { x: number, y: number }, x: number) {
    const deltaX = b.x - a.x
    const deltaY = b.y - a.y
    const delta = x - a.x

    return a.y + delta * deltaY / deltaX
}
